<div class="listBox row">
    <input (keyup)="onKeydownEvent()" [(ngModel)]="filterFor" placeholder="Type to Filter"/>
    <span *ngIf="isLoading" class="spinner"></span>

    <div class="text row">
        <div (click)="selected(name)" *ngFor="let name of names" [ngClass]="{ clickable: clickable }" class="listText">
            <div *ngIf="allowRemove" class="icon-clear" (click)="remove(name)"></div>
            <span class="preview-name" matTooltip="{{tooltip}}" matTooltipPosition="below">{{ name }}</span>
        </div>
    </div>
</div>
